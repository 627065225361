import { memo, ReactNode } from "react";
import styled from "styled-components";
import { Flex } from "../../components";

const Wrapper = styled.div<{ $isSide: boolean }>`
  width: 100%;
  height: ${({ $isSide }) => ($isSide ? "100%" : "auto")};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  padding-right: ${({ $isSide }) => ($isSide ? "32px" : "0px")};
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: ${({ $isSide }) => ($isSide ? "column" : "row")};
  }
`;

type FooterVariant = "default" | "side";

const Footer: React.FC<
  React.PropsWithChildren<{
    variant?: FooterVariant;
    helpUrl?: string;
    helpImage?: ReactNode;
  }>
> = ({ variant = "default" }) => {
  const isSide = variant === "side";
  return <Wrapper $isSide={isSide}>{isSide && <Flex flexGrow={1} />}</Wrapper>;
};

export const SwapFooter = memo(Footer);
