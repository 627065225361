import React, { cloneElement, Children, ReactElement } from "react";
import styled from "styled-components";
import { NotificationDotProps, DotProps } from "./types";

const NotificationDotRoot = styled.span<{ fullWidth?: boolean }>`
  display: inline-flex;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  position: relative;
`;

const Dot = styled.span<DotProps>`
  display: ${({ show }) => (show ? "inline-flex" : "none")};
  position: absolute;
  top: -1px;
  right: -1px;
  width: 6px;
  height: 6px;
  pointer-events: none;
  border-radius: 50%;
  background: ${({ theme, color }) => theme.colors[color]};
  filter: drop-shadow(-2px 2px 8px #eb177e);
`;

const NotificationDot: React.FC<React.PropsWithChildren<NotificationDotProps>> = ({
  show = false,
  color = "gradientPrimary",
  children,
  fullWidth,
  ...props
}) => (
  <NotificationDotRoot fullWidth={fullWidth}>
    {Children.map(children, (child: ReactElement) => cloneElement(child, props))}
    <Dot show={show} color={color} />
  </NotificationDotRoot>
);

export default NotificationDot;
