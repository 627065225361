import styled, { css } from 'styled-components'
import {
  Text,
  Flex,
  Heading,
  IconButton,
  ChevronLeftIcon,
  NotificationDot,
  QuestionHelper,
  AutoRow,
  Grid,
} from '@pancakeswap/uikit'
import { useExpertMode } from '@pancakeswap/utils/user'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
import { SettingsMode } from '../Menu/GlobalSettings/types'

interface Props {
  title: string | React.ReactNode
  subtitle?: string
  helper?: string
  backTo?: string | (() => void)
  noConfig?: boolean
  IconSlot?: React.ReactNode
  buttons?: React.ReactNode
  filter?: React.ReactNode
  shouldCenter?: boolean
  borderHidden?: boolean
}

const AppHeaderContainer = styled(Flex)<{ borderHidden?: boolean }>`
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};

  ${({ borderHidden }) =>
    borderHidden &&
    css`
      border-bottom: 1px solid transparent;
    `}
`

const FilterSection = styled(AutoRow)`
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

export const StyledGlobalSettings = styled(GlobalSettings)`
  color: ${({ theme }) => theme.colors.gray100};
  background: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  padding: 8px;
  min-width: 40px;
  width: 100%;
`

const StyledIconsWrapper = styled(Flex)`
  gap: 16px;
`

const StyledBackIcon = styled(IconButton)`
  padding: 8px;
  width: 40px;
  height: 40px;
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  helper,
  backTo,
  noConfig = false,
  IconSlot = null,
  buttons,
  filter,
  shouldCenter = false,
  borderHidden = false,
}) => {
  const [expertMode] = useExpertMode()

  return (
    <AppHeaderContainer borderHidden={borderHidden}>
      <Flex alignItems="center" width="100%">
        {backTo &&
          (typeof backTo === 'string' ? (
            <Link legacyBehavior passHref href={backTo}>
              <StyledBackIcon as="a">
                <ChevronLeftIcon width="24px" />
              </StyledBackIcon>
            </Link>
          ) : (
            <StyledBackIcon p="8px" onClick={backTo}>
              <ChevronLeftIcon width="24px" />
            </StyledBackIcon>
          ))}
        <Flex pr={backTo && shouldCenter ? '48px' : ''} flexDirection="column" width="100%">
          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" style={{ gap: '16px' }}>
            <Grid>
              <Flex flex={1} justifyContent={shouldCenter ? 'center' : ''}>
                {typeof title === 'string' ? (
                  <Heading as="h2" color="gray100">
                    {title}
                  </Heading>
                ) : (
                  title
                )}
                {helper && <QuestionHelper text={helper} ml="4px" placement="top" />}
              </Flex>

              {subtitle && (
                <Flex alignItems="center" justifyContent={shouldCenter ? 'center' : ''}>
                  <Text textAlign={shouldCenter ? 'center' : 'inherit'} color="gray200" lineHeight="1.75">
                    {subtitle}
                  </Text>
                </Flex>
              )}
            </Grid>

            {!noConfig && (
              <StyledIconsWrapper alignItems="flex-end" mr="8px">
                <NotificationDot fullWidth show={expertMode}>
                  <StyledGlobalSettings mode={SettingsMode.SWAP_LIQUIDITY} />
                </NotificationDot>
                {IconSlot}
              </StyledIconsWrapper>
            )}
            {noConfig && buttons && (
              <Flex alignItems="center" mr="16px">
                {buttons}
              </Flex>
            )}
            {noConfig && IconSlot && <Flex alignItems="center">{IconSlot}</Flex>}
          </Flex>

          {filter && (
            <FilterSection justifyContent="space-between" gap="8px">
              {filter}
            </FilterSection>
          )}
        </Flex>
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
